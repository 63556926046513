import React, { useEffect, useContext, useState } from "react";
import Header from "./components/Header";
import CameraComponent from "./components/Camera";
import Survey from "./components/Survey";
import ModelLoadingContext, {
  ModelLoadingProvider,
} from "./contexts/ModelLoadingContext";
import useProducts from "./utils/hooks/useProducts";
import * as faceapi from "face-api.js";
import PCView from "./PCView";
import "./App.css";

const MainApp: React.FC = () => {
  // true means show PCView (landing page), false means show Survey
  const [showPCView, setShowPCView] = useState(true);
  const { setIsModelLoaded } = useContext(ModelLoadingContext);
  const { products, getProducts } = useProducts();

  // Detect if the user is on a mobile device
  const [isMobile] = useState(
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      navigator.userAgent
    )
  );

  const changeView = (showLanding: boolean) => {
    setShowPCView(showLanding);
  };

  useEffect(() => {
    async function loadModels() {
      getProducts();
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        ]);
        setIsModelLoaded(true);
      } catch (error) {
        console.error("Error loading models:", error);
      }
    }

    if (isMobile) {
      loadModels();
    }
  }, [isMobile, getProducts, setIsModelLoaded]);

  return (
    <ModelLoadingProvider>
      _{window.location.pathname === "/cosmoprof" ? null : <Header />}
      {showPCView ? (
        <PCView startNow={() => changeView(false)} />
      ) : (
        <Survey onComplete={() => changeView(true)} />
      )}
    </ModelLoadingProvider>
  );
};

export default MainApp;
