export const xmlData = [
  {
    id: 11,
    title: "Vivifying Serum",
    description:
      "Aims to prevent and reduce the first signs of ageing, the antioxidant action of this serum helps to counteract the effects of free radicals, redefine the contour of the face and stimulate cellular renewal.",
    link: "https://flanerie-skincare.com/gb/serums/11-vivifying-serum-5060762542205.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/472.jpg?v=1740474129&width=493",
    additional_image_link:
      "https://flanerie-skincare.com/298-medium_default/vivifying-serum.jpg",
    price: 49,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Serums",
    gtin: 5060762542205,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.078,
  },
  {
    id: 30,
    title: "Dark Circles Relief & Uplift Eye Serum",
    description:
      "The Dark Circles Relief & Uplift Eye Serum works to hydrate and revive the delicate eye area by reducing the appearance of dark circles and fine lines.",
    link: "https://flanerie-skincare.com/gb/serums/30-dark-circles-relief-uplift-eye-serum-5060762542212.html",
    image_link:
      "https://flanerie-skincare.com/390-medium_default/dark-circles-relief-uplift-eye-serum.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/224-medium_default/dark-circles-relief-uplift-eye-serum.jpg",
    price: 37,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Serums",
    gtin: 5060762542212,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.078,
  },
  {
    id: 31,
    title: "Revitalising Fondant Day Moisturiser",
    description:
      "This revolutionary moisturiser aims to perfectly coat the skin and soothe inflammation. It works to nourish by bringing moisture to the surface of the skin for a dewy, glowing complexion.",
    link: "https://flanerie-skincare.com/gb/moisturisers/31-revitalising-foundant-day-moisturiser-5060762542229.html",
    image_link:
      "https://flanerie-skincare.com/392-medium_default/revitalising-foundant-day-moisturiser.jpg",
    additional_image_link: [
      "https://flanerie-skincare.com/303-medium_default/revitalising-foundant-day-moisturiser.jpg",
      "https://flanerie-skincare.com/302-medium_default/revitalising-foundant-day-moisturiser.jpg",
    ],
    price: 32,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Moisturisers",
    gtin: 5060762542229,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.135,
  },
  {
    id: 32,
    title: "Face & Eye Instant Blur Primer",
    description:
      "This non-comedogenic, fragrance free primer is a silky, lightweight, universal primer that aims to instantly perfect the look of your complexion without clogging pores, working to create the ideal canvas to enhance the look and wear of your makeup.",
    link: "https://flanerie-skincare.com/gb/primers/32-face-eye-instant-blur-primer-5060762542236.html",
    image_link:
      "https://flanerie-skincare.com/393-medium_default/face-eye-instant-blur-primer.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/231-medium_default/face-eye-instant-blur-primer.jpg",
    price: 27,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Primers",
    gtin: 5060762542236,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.1,
  },
  {
    id: 34,
    title: "Antioxidising Day Moisturiser SPF 20",
    description:
      "This protecting moisturiser is gentle on all skin types and works to rebuild the skins cells. It aims to treat blemishes whilst also protecting the skin from everyday environmental stressors such as sunlight, pollution and toxins.",
    link: "https://flanerie-skincare.com/gb/moisturisers/34-antioxidising-day-moisturiser-spf-20-5060762542243.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/396.jpg?v=1740474207&width=600",
    additional_image_link: [
      "https://flanerie-skincare.com/305-medium_default/antioxidising-day-moisturiser-spf-20.jpg",
      "https://flanerie-skincare.com/304-medium_default/antioxidising-day-moisturiser-spf-20.jpg",
    ],
    price: 37,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Moisturisers",
    gtin: 5060762542243,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.153,
  },
  {
    id: 35,
    title: "24k Gold Tightening Jelly Mask",
    description:
      "The 24k Gold Tightening Jelly Mask works to provide instant tightening of the skin and aims to combat the early signs of ageing.",
    link: "https://flanerie-skincare.com/gb/masks/35-24k-gold-tightening-jelly-mask-5060762542250.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/397.jpg?v=1740474227&width=493",
    additional_image_link:
      "https://flanerie-skincare.com/227-medium_default/24k-gold-tightening-jelly-mask.jpg",
    price: 47,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542250,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.153,
  },
  {
    id: 36,
    title: "Retexturising Mask",
    description:
      "If you suffer from dry or inflamed skin, the Retexturising Mask will work to restore your skin to the preferred pH level, which is 5.5. It also works to purify the skin, smooth the complexion and reduce the appearance of fine lines and pores.",
    link: "https://flanerie-skincare.com/gb/masks/36-retexturising-mask-5060762542267.html",
    image_link:
      "https://flanerie-skincare.com/398-medium_default/retexturising-mask.jpg",
    additional_image_link: [
      "https://flanerie-skincare.com/228-medium_default/retexturising-mask.jpg",
      "https://flanerie-skincare.com/211-medium_default/retexturising-mask.jpg",
    ],
    price: 39,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542267,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.154,
  },
  {
    id: 37,
    title: "Invigorating Mask",
    description:
      "The Invigorating Mask aims to restore your skin's natural, balanced pH level and helps to ease inflammation of the skin whilst supporting your body's natural collagen production and slowing down the degradation of collagen and elastin in the skin.",
    link: "https://flanerie-skincare.com/gb/masks/37-invigorating-mask-5060762542274.html",
    image_link:
      "https://flanerie-skincare.com/400-medium_default/invigorating-mask.jpg",
    additional_image_link: [
      "https://flanerie-skincare.com/229-medium_default/invigorating-mask.jpg",
      "https://flanerie-skincare.com/210-medium_default/invigorating-mask.jpg",
    ],
    price: 39,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542274,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.15,
  },
  {
    id: 38,
    title: "Replenishing Duo Moisturiser",
    description:
      "Refresh your skin with the Sorbet Burst Replenishing Duo Moisturiser. Designed for dry, dull skin, this moisturiser will help restore and retain moisture in the skin.",
    link: "https://flanerie-skincare.com/gb/moisturisers/38-replenishing-duo-moisturiser-5060762542281.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/401.jpg?v=1740474276&width=493",
    additional_image_link: [
      "https://flanerie-skincare.com/311-medium_default/replenishing-duo-moisturiser.jpg",
      "https://flanerie-skincare.com/310-medium_default/replenishing-duo-moisturiser.jpg",
    ],
    price: 39,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Moisturisers",
    gtin: 5060762542281,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.15,
  },
  {
    id: 39,
    title: "Instant Glow Splash Mask",
    description:
      "One of the most revolutionary products at flânerie, the Instant Glow Splash Mask works to instantly reveal illuminated, glowing skin. It is designed to be the perfect base for your skincare routine as it encourages improved absorption of vital ingredients.",
    link: "https://flanerie-skincare.com/gb/masks/39-instant-glow-splash-mask-5060762542298.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/471.jpg?v=1740474293&width=493",
    additional_image_link:
      "https://flanerie-skincare.com/207-medium_default/instant-glow-splash-mask.jpg",
    price: 29,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542298,
    identifier_exists: "yes",
    condition: "new",
  },
  {
    id: 40,
    title: "Firming Body Moisturiser",
    description:
      "The Firming Body Moisturiser will help to seal in hydration, calm inflammation, boost the skin's immunity and works to reduce fine lines and the first signs of ageing.",
    link: "https://flanerie-skincare.com/gb/body/40-firming-body-moisturiser-5060762542304.html",
    image_link:
      "https://flanerie-skincare.com/394-medium_default/firming-body-moisturiser.jpg",
    additional_image_link: [
      "https://flanerie-skincare.com/232-medium_default/firming-body-moisturiser.jpg",
      "https://flanerie-skincare.com/206-medium_default/firming-body-moisturiser.jpg",
    ],
    price: 37,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Body",
    gtin: 5060762542304,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.245,
  },
  {
    id: 41,
    title: "Restorative Face & Eye Night Cream",
    description:
      "The Restorative Face & Eye Night Cream works to replenish, nourish and rejuvenate the skin's complexion whilst you sleep.",
    link: "https://flanerie-skincare.com/gb/night-creams/41-restorative-face-eye-night-cream-5060762542311.html",
    image_link:
      "https://flanerie-skincare.com/402-medium_default/restorative-face-eye-night-cream.jpg",
    additional_image_link: [
      "https://flanerie-skincare.com/237-medium_default/restorative-face-eye-night-cream.jpg",
      "https://flanerie-skincare.com/403-medium_default/restorative-face-eye-night-cream.jpg",
    ],
    price: 39,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Night Creams",
    gtin: 5060762542311,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.15,
  },
  {
    id: 42,
    title: "Resurfacing Overnight Moisturiser",
    description:
      "This leave-on night treatment is prized for its brightening, anti-inflammatory, toning and anti-aging properties. It will help ward off fine lines and works to reduce the look of discoloration, often caused by environmental pollutants.",
    link: "https://flanerie-skincare.com/gb/night-creams/42-resurfacing-overnight-moisturiser-5060762542328.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/404.jpg?v=1740474343&width=493",
    additional_image_link: [
      "https://flanerie-skincare.com/233-medium_default/resurfacing-overnight-moisturiser.jpg",
      "https://flanerie-skincare.com/202-medium_default/resurfacing-overnight-moisturiser.jpg",
    ],
    price: 39,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Night Creams",
    gtin: 5060762542328,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.152,
  },
  {
    id: 43,
    title: "Gentle Exfoliating Cryo-Gel",
    description:
      "If you have oily, dull, or uneven skin, the Gentle Exfoliating Cryo-Gel is designed for you. It works to draw in moisture from the environment, helping your skin to plump up and diminish the appearance of fine lines.",
    link: "https://flanerie-skincare.com/gb/cleansers-exfoliators-/43-gentle-exfoliating-cryo-gel-5060762542335.html",
    image_link:
      "https://flanerie-skincare.com/395-medium_default/gentle-exfoliating-cryo-gel.jpg",
    additional_image_link: [
      "https://flanerie-skincare.com/234-medium_default/gentle-exfoliating-cryo-gel.jpg",
      "https://flanerie-skincare.com/201-medium_default/gentle-exfoliating-cryo-gel.jpg",
    ],
    price: 32,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Cleansers & Exfoliators",
    gtin: 5060762542335,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.254,
  },
  {
    id: 44,
    title: "Purifying Green Clay Mask",
    description:
      "Aim to rehydrate your skin with the Purifying Green Clay Mask. With a pH level of 5, this mask works to revive dull, dry, tired skin and will also work to remove excess oil from pores, whilst exfoliating dead skin cells and reducing inflammation.",
    link: "https://flanerie-skincare.com/gb/masks/44-purifying-green-clay-mask-5060762542342.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/450.jpg?v=1740474379&width=493",
    additional_image_link:
      "https://flanerie-skincare.com/377-medium_default/purifying-green-clay-mask.jpg",
    price: 32,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542342,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.16,
  },
  {
    id: 45,
    title: "Detoxifying Day Moisturiser SPF 15",
    description:
      "This silky moisturiser works to encourage a healthier, more even-looking complexion and less visible pores.",
    link: "https://flanerie-skincare.com/gb/moisturisers/45-detoxifying-day-moisturiser-spf-15.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/399.jpg?v=1740474397&width=493",
    additional_image_link: [
      "https://flanerie-skincare.com/315-medium_default/detoxifying-day-moisturiser-spf-15.jpg",
      "https://flanerie-skincare.com/314-medium_default/detoxifying-day-moisturiser-spf-15.jpg",
    ],
    price: 37,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Moisturisers",
    identifier_exists: "no",
    condition: "new",
    shipping_weight: 0.136,
  },
  {
    id: 48,
    title: "Overnight Skin Saviours",
    description:
      "Let your skincare do all the heavy lifting whilst you dream. Take time and be mindful when applying the Overnight Skin Saviours trio, allowing your day to drift away. The eye serum will work to reawaken the eyes by reducing the appearance of dark circles and plump up those fine lines. Seal in your serum with a generous coating of the overnight moisturiser. Complete your routine by massaging the Firming Body Lotion using a circling motion all over your body. Next, climb into bed and let your products get to work! Includes: Dark Circles Relief & Uplift Eye Serum Resurfacing Overnight Moisturiser Firming Body Moisturiser",
    link: "https://flanerie-skincare.com/gb/serums/48-overnight-skin-saviours-760122163082.html",
    image_link:
      "https://flanerie-skincare.com/413-medium_default/overnight-skin-saviours.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/419-medium_default/overnight-skin-saviours.jpg",
    price: 104,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Serums",
    gtin: 760122163082,
    identifier_exists: "yes",
    condition: "new",
  },
  {
    id: 49,
    title: "Wellness Duo",
    description:
      "Our Wellness Duo explores self-care as an all-immersive sensory experience. Including two of our beautiful masks, this duo works to revitalise your complexion, revealing young supple skin. The 24k Gold Tightening Jelly Mask works to revive dull and tired skin and restore radiance whilst the Invigorating Mask is designed to plump and rejuvenate the skin. Includes: 24k Gold Tightening Jelly Mask Invigorating Mask",
    link: "https://flanerie-skincare.com/gb/masks/49-wellness-duo-760122163099.html",
    image_link:
      "https://flanerie-skincare.com/414-medium_default/wellness-duo.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/418-medium_default/wellness-duo.jpg",
    price: 79,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 760122163099,
    identifier_exists: "yes",
    condition: "new",
  },
  {
    id: 50,
    title: "Find Balance In Me",
    description:
      "The Find Balance In Me trio is designed to reset the skin and restore it to its perfect state. Through exposure to environmental stressors your skin's natural pH can be disrupted. The Gentle Exfoliating Cryo-Gel will work to kickstart your rebalance by gently buffing away dead skin cells and other impurities on the skin. The Vivifying Serum will help provide your skin with the necessary actives to rejuvenate the skin, whilst the Retexturising Mask will seal in all vital ingredients to hydrate and replenish. Includes: Gentle Exfoliating Cryo-Gel Vivifying Serum Retexturising Mask",
    link: "https://flanerie-skincare.com/gb/cleansers-exfoliators-/50-find-balance-in-me-760122163105.html",
    image_link:
      "https://flanerie-skincare.com/415-medium_default/find-balance-in-me.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/417-medium_default/find-balance-in-me.jpg",
    price: 109,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Cleansers & Exfoliators",
    gtin: 760122163105,
    identifier_exists: "yes",
    condition: "new",
  },
  {
    id: 51,
    title: "Totebag",
    description: "",
    link: "https://flanerie-skincare.com/gb/presents/51-totebag.html",
    image_link: "https://flanerie-skincare.com/345-medium_default/totebag.jpg",
    price: 20,
    availability: "in stock",
    product_type: "",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 61,
    title: "Vivifying Serum",
    description:
      "Aims to prevent and reduce the first signs of ageing, the antioxidant action of this serum helps to counteract the effects of free radicals, redefine the contour of the face and stimulate cellular renewal.",
    link: "https://flanerie-skincare.com/products/vivifying-serum?_pos=1&_sid=508b93491&_ss=r",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/472.jpg?v=1740474129&width=600",
    additional_image_link:
      "https://flanerie-skincare.com/353-medium_default/vivifying-serum.jpg",
    price: 62,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Serums",
    gtin: 5060762542205,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.099,
  },
  {
    id: 62,
    title: "Dark Circles Relief & Uplift Eye Serum",
    description:
      "The Dark Circles Relief & Uplift Eye Serum works to hydrate and revive the delicate eye area by reducing the appearance of dark circles and fine lines.",
    link: "https://flanerie-skincare.com/gb/serums/62-dark-circles-relief-uplift-eye-serum-5060762542212.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/390.jpg?v=1740474147&width=493",
    additional_image_link:
      "https://flanerie-skincare.com/356-medium_default/dark-circles-relief-uplift-eye-serum.jpg",
    price: 47,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Serums",
    gtin: 5060762542212,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.078,
  },
  {
    id: 63,
    title: "Retexturising Mask",
    description:
      "If you suffer from dry or inflamed skin, the Retexturising Mask will work to restore your skin to the preferred pH level, which is 5.5. It also works to purify the skin, smooth the complexion and reduce the appearance of fine lines and pores.",
    link: "https://flanerie-skincare.com/gb/masks/63-retexturising-mask-5060762542267.html",
    image_link:
      "https://flanerie-skincare.com/409-medium_default/retexturising-mask.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/360-medium_default/retexturising-mask.jpg",
    price: 47,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542267,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.154,
  },
  {
    id: 64,
    title: "Invigorating Mask",
    description:
      "The Invigorating Mask aims to restore your skin's natural, balanced pH level and helps to ease inflammation of the skin whilst supporting your body's natural collagen production and slowing down the degradation of collagen and elastin in the skin.",
    link: "https://flanerie-skincare.com/gb/masks/64-invigorating-mask-5060762542274.html",
    image_link:
      "https://flanerie-skincare.com/408-medium_default/invigorating-mask.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/364-medium_default/invigorating-mask.jpg",
    price: 47,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Masks",
    gtin: 5060762542274,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.152,
  },
  {
    id: 66,
    title: "Restorative Face & Eye Night Cream",
    description:
      "The Restorative Face & Eye Night Cream works to replenish, nourish and rejuvenate the skin's complexion whilst you sleep.",
    link: "https://flanerie-skincare.com/gb/night-creams/66-restorative-face-eye-night-cream-5060762542311.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/402.jpg?v=1740474328&width=493",
    additional_image_link:
      "https://flanerie-skincare.com/371-medium_default/restorative-face-eye-night-cream.jpg",
    price: 49,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Night Creams",
    gtin: 5060762542311,
    identifier_exists: "yes",
    condition: "new",
    shipping_weight: 0.15,
  },
  {
    id: 67,
    title: "Restorative Face & Eye Night Cream 10ml",
    description: "",
    link: "https://flanerie-skincare.com/gb/presents/67-totebag.html",
    image_link: "https://flanerie-skincare.com/411-medium_default/totebag.jpg",
    price: 20,
    availability: "in stock",
    product_type: "",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 71,
    title: "Nourishing Lip Treatment",
    description:
      "This treatment helps you achieve soft, plump and restored lips. With a naturally, sweet flavour, the Nourishing Lip Treatment helps provide intense moisture.",
    link: "https://flanerie-skincare.com/gb/body/71-nourishing-lip-treatment.html",
    image_link:
      "https://flanerie-skincare.com/426-medium_default/nourishing-lip-treatment.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/425-medium_default/nourishing-lip-treatment.jpg",
    price: 24,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Body",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 73,
    title: "Purifying Cleansing Powder",
    description:
      "A gentle, yet effective soothing cleansing powder formulated to clarify, calm and soften skin. Enriched with Green Tea Extract and Kaolin, the Purifying Cleansing Powder is designed for dry and sensitive skin",
    link: "https://flanerie-skincare.com/gb/cleansers-exfoliators-/73-purifying-cleansing-powder.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/470.jpg?v=1740474609&width=823",
    additional_image_link:
      "https://flanerie-skincare.com/435-medium_default/purifying-cleansing-powder.jpg",
    price: 37,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Cleansers & Exfoliators",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 74,
    title: "Pore Refining Exfoliator",
    description:
      "Our Pore Refining Exfoliator is formulated to help brighten, smooth and purify the skin. Designed for dull complexions, it works to reveal a radiant complexion. This gentle exfoliator is formulated with skin-soothing and antioxidant properties, that aims to maintain a healthy skin barrier.",
    link: "https://flanerie-skincare.com/gb/cleansers-exfoliators-/74-pore-refining-exfoliator-.html",
    image_link:
      "https://flanerie-skincare.com/437-medium_default/pore-refining-exfoliator-.jpg",
    additional_image_link:
      "https://flanerie-skincare.com/436-medium_default/pore-refining-exfoliator-.jpg",
    price: 44,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Cleansers & Exfoliators",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 76,
    title: "Revitalising Micellar Water",
    description:
      "The Revitalising Micellar Water works to gently lift away impurities and makeup. Formulated with skin-softening and protecting ingredients to help keep your skin clear and revitalised.",
    link: "https://flanerie-skincare.com/gb/cleansers-exfoliators-/76-revitalising-micellar-water.html",
    image_link:
      "https://flanerie-skincare.com/438-medium_default/revitalising-micellar-water.jpg",
    price: 27,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Cleansers & Exfoliators",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 78,
    title: "Brightening Eye Cream",
    description:
      "The Brightening Eye Cream helps provide a soft glow to the eye contour. The blend of Vitamin C, Peptides and Hyaluronic Acid works to improve skin firmness and helps to provide intense hydration.",
    link: "https://flanerie-skincare.com/gb/eye-care/78-brightening-eye-cream.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/460.jpg?v=1740474636&width=493",
    price: 34,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Eye Care",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 80,
    title: "Balancing Gelée Cleanser",
    description:
      "The Balancing Gelée Cleanser is a gentle formula with skin-brightenting and makeup removing properties. The combination of Niacinamide, Glycerin and Vitamin E is designed to support the skin’s natural skin barrier. A perfect first step in your daily skincare routine.",
    link: "https://flanerie-skincare.com/gb/cleansers-exfoliators-/80-balancing-gelee-cleanser.html",
    image_link:
      "https://flanerie-skincare.com/cdn/shop/files/468.jpg?v=1740474644&width=493",
    price: 32,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Cleansers & Exfoliators",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 81,
    title: "Restoring Hair Treatment",
    description:
      "Works to provide your hair with a new lease for life, the Restoring Hair Treatement aims to revive and restore damaged hair.",
    link: "https://flanerie-skincare.com/gb/body/81-restoring-hair-treatment.html",
    image_link:
      "https://flanerie-skincare.com/441-medium_default/restoring-hair-treatment.jpg",
    price: 39,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Body",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 82,
    title: "Mood-Enhancing Capsules",
    description:
      "Infused with the restorative benefits of Vitamin B12, Chamomile and Lemon Balm, our Mood-Enhancing Capsules work towards sustaining your overall mental well-being.",
    link: "https://flanerie-skincare.com/gb/home/82-mood-enhancing-capsules.html",
    image_link:
      "https://flanerie-skincare.com/442-medium_default/mood-enhancing-capsules.jpg",
    price: 34,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "",
    identifier_exists: "no",
    condition: "new",
  },
  {
    id: 85,
    title: "Brightening Eye Cream",
    description:
      "The Brightening Eye Cream helps provide a soft glow to the eye contour. The blend of Vitamin C, Peptides and Hyaluronic Acid works to improve skin firmness and helps to provide intense hydration.",
    link: "https://flanerie-skincare.com/gb/eye-care/85-brightening-eye-cream.html",
    image_link:
      "https://flanerie-skincare.com/447-medium_default/brightening-eye-cream.jpg",
    price: 47,
    availability: "in stock",
    google_product_category:
      "Health & Beauty > Personal Care > Cosmetics > Skin Care",
    product_type: "Shop > Category > Eye Care",
    identifier_exists: "no",
    condition: "new",
  },
];
