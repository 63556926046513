import { useState, useCallback } from "react";
import { xmlData } from "./products.js";

// Define a proper Product interface that matches your data
interface Product {
  id: number;
  title: string;
  description: string;
  link: string;
  image_link: string;
  additional_image_link: string | string[];
  price: number;
  availability: string;
  google_product_category: string;
  product_type: string;
  gtin: number;
  identifier_exists: string;
  condition: string;
  shipping_weight: number;
}

const useProducts = () => {
  const [products, setProducts] = useState<Product[] | null>(null);

  const getProducts = useCallback(() => {
    try {
      // Cast xmlData as Product[] so it matches our state type
      setProducts(xmlData as Product[]);
      console.log(xmlData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, []);

  return {
    products,
    getProducts,
  };
};

export default useProducts;
