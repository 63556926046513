import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Collapse,
  IconButton,
  Button, // import Button from MUI
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
const primaryColor = "#B7BCA1";

const NextButton = styled(Button)({
  width: "250px",
  height: 45,
  margin: "8px",
  borderRadius: 20,
  color: "white",
  border: `1px solid ${primaryColor}`,
  fontFamily: "Cormorant",
});

// Import images from src folder
const mainImage = require("./assets/landing.png");
const sec1 = require("./assets/sec1.jpg");
const sec2 = require("./assets/sec2.jpg");
const sec3 = require("./assets/sec3.jpg");
const sec4 = require("./assets/sec4.jpg");

const sections = [
  {
    title: "Tell us about your skin",
    text: "Scan the QR code and answer three questions to help us gain an insight into your skin type and concerns so that we can match the optimal formula for your skin.",
    image: sec1,
  },
  {
    title: "Take your selfie",
    text: "Use your phone camera to take a photo* of your face in bright, natural sunlight. Keep your eyes open and relax your face to a neutral expression. Ensure your skin is clean and free from makeup for the most accurate results.",
    image: sec2,
  },
  {
    title: "Review your results",
    text: "Receive your personalised skin health analysis and product recommendations.",
    image: sec3,
  },
  {
    title: "Discover your exclusive Flanerie routine",
    text: "Explore your Flanerie skincare routine for best results, including cleansers, serums, and moisturisers. Let’s illuminate your glow.",
    image: sec4,
  },
];

interface PCViewProps {
  startNow?: () => void;
}

const PCView: React.FC<PCViewProps> = ({ startNow }) => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [showFloating, setShowFloating] = useState(false);
  // Ref for the original Start Now button container
  const buttonRef = useRef<HTMLDivElement>(null);

  // Check if the original button is in view
  useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        // If the top of the button container is above the viewport,
        // then show the floating button
        if (rect.top < 0) {
          setShowFloating(true);
        } else {
          setShowFloating(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Call it once on mount
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleTerms = () => {
    setIsTermsOpen((prev) => {
      const newState = !prev;
      if (newState) {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }
      return newState;
    });
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          fontFamily: "Cormorant",
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        {/* Top Full-Width Image Section */}
        <Box sx={{ marginBottom: "50px", position: "relative", width: "100%" }}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          >
            <source src={require("./assets/landing.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
            fontFamily: "Cormorant",
          }}
        >
          <Typography
            variant="h2"
            pb={2}
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              fontFamily: "Cormorant",
              fontSize: "12mm",
            }}
          >
            How it Works
          </Typography>
          <Typography
            sx={{
              color: "black",
              maxWidth: "900px",
              margin: "0 auto",
              lineHeight: 1.8,
              fontFamily: "Cormorant",
              fontSize: "6mm",
            }}
          >
            Our advanced AI technology analyses wrinkles, firmness, expression
            lines, dark spots, oiliness, visible pores, radiance, redness, and
            texture. Based on these insights, we’ll provide you with a
            personalised skincare routine curated for your specific needs.
          </Typography>
        </Box>

        {/* Original Button Container */}
        {window.location.pathname === "/cosmoprof" && (
          <Box
            ref={buttonRef}
            sx={{ textAlign: "center", marginBottom: "100px" }}
          >
            <NextButton
              variant="contained"
              onClick={startNow}
              sx={{
                backgroundColor: primaryColor,
                "&:hover": {
                  backgroundColor: primaryColor,
                },
                color: "white",
                border: "none",
              }}
            >
              Start Now
            </NextButton>
          </Box>
        )}

        {/* Sections */}
        {sections.map((section, index) => {
          const isEven = index % 2 === 0;

          return (
            <Grid
              container
              key={index}
              spacing={0} // eliminate gaps between grid items
              alignItems="center" // ensure both grid items share the same vertical center
              sx={{ marginBottom: "40px" }}
            >
              {/* Text Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 1, md: isEven ? 1 : 2 }} // mobile: text always on top; desktop: alternate order
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // vertically center the content
                  alignItems: "center", // horizontally center the content
                  padding: 0,
                  height: "100%",
                  fontFamily: "Cormorant",
                }}
              >
                {/* Wrap in a Box with a maxWidth of the image's width on desktop */}
                <Box
                  sx={{
                    width: "100%",
                    fontFamily: "Cormorant",
                    maxWidth: { md: "400px", xs: "100%" },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Cormorant",
                      textAlign: "left",
                    }}
                  >
                    0{index + 1}.
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "black",
                      textAlign: "left",
                      fontFamily: "Cormorant",
                    }}
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      textAlign: "left",
                      maxWidth: index === 2 ? "270px" : "400px",
                      fontFamily: "Cormorant",
                      fontSize: "5.5mm",
                    }}
                  >
                    {section.text}
                  </Typography>
                </Box>
              </Grid>

              {/* Image Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: isEven ? 2 : 1 }} // mobile: image always below; desktop: alternate order
                sx={{
                  padding: 0,
                  display: "flex",
                  justifyContent: "center", // align the image within the grid cell
                }}
              >
                <Box
                  component="img"
                  src={section.image}
                  alt={`Section ${index + 1}`}
                  sx={{
                    width: { xs: "100%", md: "530px" },
                    height: "auto",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    margin: 0,
                  }}
                />
              </Grid>
            </Grid>
          );
        })}

        {/* Terms and Conditions */}
        <Box
          sx={{
            textAlign: "center",
            marginTop: "50px",
          }}
          pb={15}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              display: "inline-block",
              verticalAlign: "middle",
              fontFamily: "Cormorant",
            }}
          >
            Flanerie Virtual Skin Analysis Terms & Conditions
          </Typography>
          <IconButton
            onClick={toggleTerms}
            sx={{
              verticalAlign: "middle",
              marginLeft: "10px",
            }}
          >
            {isTermsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Collapse in={isTermsOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "black",
                  lineHeight: 1.6,
                  textAlign: "center",
                  fontFamily: "Cormorant",
                  maxWidth: "800px",
                  margin: "0 auto",
                }}
              >
                Our virtual consultation offers personalised skincare
                recommendations. Please note that it is not a substitute for
                professional guidance. Flanerie is not responsible for service
                delivery issues caused by technical or connectivity problems.
                Users must be at least 16 years old to access this service. Your
                photo is not retained and is deleted immediately after the skin
                health analysis. Flanerie uses your input to generate a skin
                health analysis report and recommend personalised products. By
                utilising this service, you acknowledge that your interaction is
                with Flanerie and that your data is processed in compliance with
                Flanerie's Privacy Policy.
              </Typography>
            </Box>
          </Collapse>
        </Box>
      </Container>

      {/* Floating Button at the Bottom */}
      {window.location.pathname === "/cosmoprof" && showFloating && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0px", // 20px from bottom edge
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1300,
            // Optionally, add a darkened background for better visibility
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            padding: "10px",
            borderRadius: "10px",
            width: "100%",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <NextButton
            variant="contained"
            onClick={startNow}
            sx={{
              backgroundColor: primaryColor,
              "&:hover": {
                backgroundColor: primaryColor,
              },
              color: "white",
              border: "none",
            }}
          >
            Start Now
          </NextButton>
        </Box>
      )}
    </>
  );
};

export default PCView;
